
<script setup>
    import operation from "mobile.controls.OperationContext.js";
</script>

<template>
    <o-data-lookup
        class="form-control"
        :placeholder="$t('Operation Context')"
        :data-object="operation.lookup"
        v-model="operation.dataObject.OrgUnit"
        :bind="sel => { operation.dataObject.OrgUnit = sel.OrgUnit; operation.dataObject.ID = sel.OrgUnit_ID; operation.dataObject.save(); }"
    >
        <o-column field="OrgUnit" />
    </o-data-lookup>
</template>
